<template>
  <layout-single>
    <template #content>
      <v-container class="px-8 py-8">
        <v-row
          v-for="scope in evaluation"
          :key="scope.scope"
          ripple
          class="cursor-pointer evaluation-section"
        >
          <v-col align="left">
            <div
              v-if="!$route.params.extraId && $t(scope.title)"
              class="section-title"
              id="tooltip"
            >
              <div class="title">
                {{ $t(scope.title) }}
              </div>

              <v-dialog class="tooltip" v-if="scope.tooltip" max-width="600px">
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mb-0"
                    v-bind="attrs"
                    v-on="on"
                    @click="scope.showTooltip = !scope.showTooltip"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>

                <template #default="dialog">
                  <v-card>
                    <v-card-title class="headline">
                      <slot name="title">
                        {{ $t(scope.title) }}
                      </slot>
                    </v-card-title>

                    <v-card-text
                      class="headline"
                      :key="s"
                      v-for="s in $t(scope.tooltip).split('\n')"
                    >
                      <slot name="text">
                        {{ s }}
                      </slot>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        @click="dialog.value = false"
                        class="blue white--text"
                      >
                        Stäng
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>

            <evaluation-inputs
              v-if="scope"
              ref="evaluation"
              @change="onChange"
              :inputs="scope.inputs"
              :registration="registration"
              :values="values"
            />
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0 pt-8">
          <v-col class="ma-0 pa-0">
            <v-list-item-content>
              <v-btn
                elevation="0"
                color="primary"
                class="block"
                :large="$vuetify.breakpoint.name !== 'xs'"
                :class="{ 'blue white--text': true }"
                @click="saveResults"
              >
                {{ $t('Save') }}
              </v-btn>

              <v-btn
                style="margin-top: 20px;"
                elevation="0"
                color="default"
                :large="$vuetify.breakpoint.name !== 'xs'"
                @click="onClose"
              >
                {{ $t('Close') }}
              </v-btn>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout-single>
</template>

<script>
import LayoutSingle from '@/components/layout-single';
import EvaluationInputs from '@/components/evaluation/inputs.vue';

export default {
  name: 'RegistrationEvaluation',

  components: {
    LayoutSingle,
    EvaluationInputs,
  },

  data: () => ({
    showModal: false,
    selectedScope: 0,
    rawCompetitor: {},
    evaluation: [],
    values: [],
    categories: {},
  }),

  computed: {
    event() {
      return this.$store.state.rx.events.find(
        (e) => e.id === this.$route.params.eventId,
      );
    },

    test() {
      return this.$store.state.rx.tests.find((t) => t.type === this.event.type);
    },

    registration() {
      return this.$store.state.rx.registrations.find(
        (r) => r.id === this.$route.params.registrationId,
      );
    },

    currentEvaluation() {
      if (this.$route.params.extraId) {
        return [this.test.evaluation.extra[this.$route.params.extraId]];
      }
      return this.test.evaluation.registration;
    },
  },

  mounted() {
    if (!this.event) {
      this.$router.push('/event').catch(() => {});
    } else if (!this.registration) {
      this.$router.push(`/event/${this.$route.params.eventId}`).catch(() => {});
    }

    let title = "Dog's evaluation";

    if (this.$route.params.extraId) {
      title = this.test.evaluation.extra[this.$route.params.extraId].title;
    }

    this.$store.commit('setNavigation', {
      show: true,
      title,
      to: `/event/${this.$route.params.eventId}/registration/${this.$route.params.registrationId}`,
    });

    this.event.categories.forEach((c) => {
      if (!this.categories[c.type.toLowerCase()]) {
        this.categories[c.type.toLowerCase()] = c.value;
      }
    });

    this.values = [
      ...this.registration.results,
      ...this.event.results,
      ...Object.keys(this.categories).map((c) => ({
        type: `category_${c}`,
        value_text: this.categories[c],
      })),
    ];

    this.values = _.uniqBy(this.values, (v) => {
      return v.type;
    });

    const { classes, provform } = this.categories;
    let regClass = this.registration.class ? this.registration.class : classes;

    this.evaluation = this.currentEvaluation
      .filter(
        (e) =>
          (e.class === undefined || regClass === e.class) &&
          (e.provform === undefined || provform === e.provform),
      )
      .map((e) => ({
        ...e,
        selectedValue: '',
        showTooltip: false,
      }));
  },

  methods: {
    onClose() {
      this.redirect(
        `/event/${this.$route.params.eventId}/registration/${this.$route.params.registrationId}`,
      );
    },

    onChange(data) {
      data.forEach((d) => {
        const target = this.values.find((v) => v.type === d.type);

        this.$emit('change', {
          target: d.type,
          ...d,
        });

        if (!target) {
          this.values[d.type] = { ...d };
          return;
        }

        target.comment = d.comment;
        target.value = d.value;
        target.value_text = d.value_text;
      });
    },

    async saveResults() {
      if (
        !this.$refs.evaluation ||
        Object.values(this.$refs.evaluation).length === 0
      ) {
        this.onClose();
        return;
      }

      const results = Object.values(this.$refs.evaluation)
        .map((e) => e.results())
        .flat();

      if (!results.length) {
        this.onClose();
        return;
      }

      try {
        await this.registration.atomicUpdate((reg) => {
          const evaluations = this.$nosework.getEvaluations(
            this.currentEvaluation,
            {
              checkRules: this.values,
            },
          );

          reg.results = [
            ...reg.results.filter(
              (r) => !evaluations.some((e) => e.test(r.type)),
            ),
            ...results,
          ];

          return reg;
        });
      } catch (err) {
        console.log(err);
      }

      this.onClose();
    },
  },
};
</script>

<style lang="scss">
.evaluation {
  .__view {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
}

.points-input {
  .v-input__slot::before {
    border-color: transparent !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
/* .evaluation-section {
    margin-bottom: 20px;
  } */
.section-title {
  border-bottom: 1px solid lighten(#000, 80%);
  display: flex;
  margin-bottom: 16px;
  padding-bottom: 6px;
  .title {
    font-size: 14px;
    font-weight: bold;
    flex-grow: 1;
  }
  .tooltip {
    margin-top: -1px;
  }
}
</style>
